import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const lenfantduvillage = () => (
  <Layout>
    <SEO title="Sentier et Chemin - L'enfant du village" />
    <h3 className='underline-title'>L'enfant du village</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        C'était un matin<br />
        Un matin de soleil entier<br />
        Soleil ivre de luminosité<br />
        Luminosité généreuse<br />
      </p>
      <p>
        Matin de soleil<br />
        Soleil gratuit<br />
        Pour un enfant du village<br />
        N'ayant rien fait<br />
        Pour mériter ainsi <br />
        Tant de générosité lumineuse <br />
      </p>
      <p>
        Enfant du village<br />
        Vautré à plein corps<br />
        Dans le sable alors assainissant<br />
        Grisé de fraîcheur et de liberté <br />
      </p>
      <p>
        O enfant du village<br />
        Village du soleil matinal <br />
        Soleil du village matin <br />
        O! enfant du village<br />
        Sentier libre de l'esprit<br />
        Négation vivante du Gavroche kinois <br />
        Sophistiqué et bourré d'artifices <br />
      </p>
      <p>
        Enfant du village d'autrefois:<br />
        Liberté<br />
        Gratuité <br />
        Santé<br />
      </p>
      <p>
        Enfant du village d'autan:<br />
        Innoncence <br />
        Croissance <br />
        Providence <br />
      </p>
      <p>
        Enfant du village de jadis: <br />
        Quiétude <br />
        Plénitude <br />
        Béatitude <br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default lenfantduvillage
